import { useDefaultCartSteps, useSkualoCartSteps } from '.';
import useGetRequestingPlatform from '../useGetRequestingPlatform';

const useCartStepsHookProvider = () => {
  const requestingPlatform = useGetRequestingPlatform();
  const availableHooks = {
    FINGO: useDefaultCartSteps,
    SKUALO: useSkualoCartSteps,
  };

  const cartHhook = availableHooks[requestingPlatform?.code] || useDefaultCartSteps;
  return cartHhook();
};

export default useCartStepsHookProvider;
