import React from 'react';
import { useQuery } from '@apollo/client';
import { preofferSelectedQuery } from '@fingo/lib/apollo/cache/localQuery';
import { preofferSelected } from '@fingo/lib/apollo/reactive-variables/invoices';
import { PREOFFERS, TODAY, TWO_MONTHS_AGO } from '@fingo/lib/constants';
import FingoMainViewV2 from '@fingo/lib/components/layout/FingoMainViewV2';
import ShoppingCartSearchHeader from '@fingo/lib/components/headers/ShoppingCartSearchHeader';
import { AVAILABLE_FACTORING } from '@fingo/lib/graphql';
import { useSelectedCompany } from '@fingo/lib/hooks';
import NoRowsGeneric from '@fingo/lib/components/text/NoRowsGeneric';
import InvoicesActions from '../components/invoices/InvoicesActions';
import { COLUMNS, SUBTITLE, TITLE } from '../constants/invoices';
import InvoicesSummary from '../components/invoices/InvoicesSummary';

const Invoices = () => {
  const { data: { selectedPreofferIds } } = useQuery(preofferSelectedQuery);
  const selectedCompany = useSelectedCompany();
  const isRowSelectable = ({ row }) => (row.preofferSelectable?.selectable);
  return (
    <FingoMainViewV2
      id="invoices"
      apolloQuery={{
        query: AVAILABLE_FACTORING,
        orderBy: { field: 'pk', sort: 'desc' },
        variables: {
          availableForFinancing: 'AVAILABLE',
          companyId: selectedCompany?.id,
          preoffer_Preofferevaluationrequest_Isnull: true,
          dateIssued_Gte: TWO_MONTHS_AGO.format('YYYY-MM-DD'),
          dateIssued_Lte: TODAY.format('YYYY-MM-DD'),
        },
      }}
      slots={{
        header: ShoppingCartSearchHeader,
        summary: InvoicesSummary,
        actions: InvoicesActions,
      }}
      slotProps={{
        headerProps: {
          title: TITLE,
          subtitle: SUBTITLE,
          shoppingCartType: PREOFFERS,
          shoppingCartItems: selectedPreofferIds,
        },
        summaryProps: { open: selectedPreofferIds.length !== 0 },
        tableProps: {
          columns: COLUMNS,
          checkboxSelection: true,
          disableSelectionOnClick: true,
          onSelectionModelChange: (ids) => {
            preofferSelected(ids);
          },
          noRowsMessage: NoRowsGeneric,
          selectionModel: selectedPreofferIds,
          isRowSelectable,
        },
      }}
    />
  );
};

export default Invoices;
