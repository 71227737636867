import React, { useCallback, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import NationalTaxServiceCredentialsDialog from '@fingo/lib/components/dialogs/NationalTaxServiceCredentialsDialog';
import Onboarding from '../components/home/Onboarding';
import useGetRequestingPlatform from './useGetRequestingPlatform';

const useHomeSteps = () => {
  const history = useHistory();
  const [activeStep, setActiveStep] = useState(0);
  const requestingPlatform = useGetRequestingPlatform();

  const goNextStep = useCallback(() => {
    setActiveStep(activeStep + 1);
  }, []);

  const steps = useMemo(
    () => [
      { id: 'onboarding', Component: <Onboarding goNextStep={goNextStep} /> },
      {
        id: 'national-tax-service-credentials',
        Component: (
          <NationalTaxServiceCredentialsDialog source={requestingPlatform?.code} onCompleted={() => history.push('/invoices')} />
        ),
      },
    ],
    [],
  );

  return [steps, activeStep, goNextStep];
};

export default useHomeSteps;
