import { useQuery, useReactiveVar } from '@apollo/client';
import { useEffect } from 'react';
import { requestingPlatformIdVar } from '@fingo/lib/apollo/reactive-variables/platform';
import { GET_REQUESTING_PLATFORM } from '@fingo/lib/graphql';

const useGetRequestingPlatform = (onCompleted = () => {}) => {
  const requestingPlatformId = useReactiveVar(requestingPlatformIdVar);
  const { data, loading, refetch, error } = useQuery(GET_REQUESTING_PLATFORM, {
    variables: { id: requestingPlatformId },
    onCompleted,
    onError: () => setTimeout(refetch, 1500),
  });
  useEffect(() => {
    if (!error && !loading && data?.getRequestingPlatform) {
      refetch();
    }
  }, [loading, data]);
  return data?.getRequestingPlatform;
};

export default useGetRequestingPlatform;
