import React from 'react';
import FingoMainViewV2 from '@fingo/lib/components/layout/FingoMainViewV2';
import SearchHeader from '@fingo/lib/components/headers/SearchHeader';
import { useSelectedCompany } from '@fingo/lib/hooks';
import { CLIENT_ASSIGNMENTS } from '@fingo/lib/graphql';
import NoRowsGeneric from '@fingo/lib/components/text/NoRowsGeneric';
import { COLUMNS, TITLE } from '../constants/cessions';
import CessionsSummary from '../components/cessions/CessionsSummary';

const Cessions = () => {
  const selectedCompany = useSelectedCompany();
  return (
    <FingoMainViewV2
      id="cessions"
      apolloQuery={{
        query: CLIENT_ASSIGNMENTS,
        variables: { hasBeenCeded: true, companyId: selectedCompany?.id },
      }}
      slots={{
        header: SearchHeader,
        summary: CessionsSummary,
      }}
      slotProps={{
        headerProps: { title: TITLE },
        tableProps: {
          columns: COLUMNS,
          noRowsMessage: NoRowsGeneric,
        },
      }}
    />
  );
};

export default Cessions;
