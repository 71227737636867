import React from 'react';
import Box from '@mui/material/Box';
import MobileStepper from '@mui/material/MobileStepper';
import Stack from '@mui/material/Stack';
import { WaveFullHeightBox } from '@fingo/lib/components/boxes';
import useHomeSteps from '../hooks/useHomeSteps';

const Home = () => {
  const [steps, activeStep] = useHomeSteps();
  const { Component } = steps[activeStep];

  return (
    <WaveFullHeightBox>
      <Box sx={{ flexGrow: 1 }}>
        <MobileStepper
          variant="dots"
          steps={steps.length}
          position="static"
          activeStep={activeStep}
          sx={{
            justifyContent: 'center',
            backgroundColor: 'transparent',
            my: 2,
          }}
        />
        <Stack justifyContent="center" alignItems="center" spacing={1}>
          {Component}
        </Stack>
      </Box>
    </WaveFullHeightBox>
  );
};

export default Home;
