import merge from 'lodash/merge';

const getCustomTypography = (customConfig) => ({
  h1: { fontSize: customConfig.h1 },
});

export const defaultTypography = {
  fontFamily: 'Be Vietnam Pro',
  h0: {
    fontWeight: 'bold',
    fontFamily: 'Plus Jakarta Sans',
    fontSize: 30,
    lineHeight: 1,
  },
  h1: {
    fontWeight: 'bold',
    fontFamily: 'Plus Jakarta Sans',
    fontSize: 25,
    lineHeight: 1,
  },
  h2: {
    fontWeight: '400',
    fontFamily: 'Plus Jakarta Sans',
    fontSize: 14,
    lineHeight: 1.1,
  },
  h3: {
    fontWeight: '500',
    fontSize: 25,
    lineHeight: 1.1,
  },
  h4: {
    fontWeight: '400',
    fontSize: 20,
    lineHeight: 1.2,
  },
  h5: {
    fontWeight: '400',
    fontSize: 16,
    lineHeight: 1.2,
  },
  h6: {
    fontWeight: '400',
    fontSize: 14,
    lineHeight: 1.2,
  },
  body1: {
    fontSize: 12,
    fontWeight: '400',
    lineHeight: 1.2,
  },
  body2: {
    fontSize: '12',
    fontWeight: '300',
    lineHeight: 1.25,
  },
  caption: {
    fontSize: 12,
    lineHeight: 1.2,
    color: '#ABABAB',
  },
  button: {
    textTransform: 'none',
    height: 50,
    fontSize: 14,
  },
  subtitle1: {
    height: 'auto',
    fontSize: 12,
    WebkitLineClamp: 2,
    display: '-webkit-box',
    overflow: 'hidden',
    WebkitBoxOrient: 'vertical',
    lineHeight: '0.8rem !important',
  },
  subtitle3: {
    height: 'auto',
    fontSize: 11,
    WebkitLineClamp: 2,
    display: '-webkit-box',
    overflow: 'hidden',
    WebkitBoxOrient: 'vertical',
    lineHeight: '0.8rem !important',
    fontWeight: 400,
  },
  formLabel: {
    fontSize: '12px',
    fontWeight: '400',
    display: '-webkit-box',
    marginBottom: '5px',
  },
  headerSubtitle: {
    color: '#000000',
    fontSize: 10,
    fontWeight: 600,
    lineHeight: 'normal',
  },
  cardHeader: {
    fontSize: 14,
    fontWeight: 700,
    lineHeight: 1.2,
  },
  shoppingCartSummary: {
    color: 'text.primary',
    fontSize: 14,
  },
  info: {
    maxWidth: 200,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    WebkitLineClamp: '1',
    WebkitBoxOrient: 'vertical',
    fontWeight: 500,
  },
  error: {
    fontSize: 14,
    lineHeight: 1.2,
    fontWeight: 500,
  },
};

export const getTypography = (customConfig) => merge(
  {},
  defaultTypography,
  getCustomTypography(customConfig),
);
