/* eslint-disable no-unused-vars */
import { FactoringOperationSummary, OkOperation } from '@fingo/lib/components/shopping-cart';
import { closeShoppingCart } from '@fingo/lib/helpers';
import { useCreateOperation, useShoppingCart, useStepper } from '@fingo/lib/hooks';
import React, { useState } from 'react';
import OfferSelectBankAndCertificatePassword from '../../components/shopping-carts/OfferShoppingCart/OfferSelectBankAndCertificatePassword';
import OfferSelectCessionType from '../../components/shopping-carts/OfferShoppingCart/OfferSelectCessionType';

const useDefaultCartSteps = () => {
  const [error, setError] = useState('');
  const [bankId, setBankId] = useState(null);
  const [isExternalCession, setIsExternalCession] = useState(true);
  const [certificatePassword, setCertificatePassword] = useState('');
  const { currentStep, goNextStep, goBackStep } = useStepper();
  const { shoppingCartData } = useShoppingCart({ cartType: 'available-offers' });
  const [createOperation, { loading }] = useCreateOperation(
    isExternalCession,
    bankId,
    certificatePassword,
    null,
    setError,
  );
  const offerShoppingSteps = [
    {
      Component: <FactoringOperationSummary shoppingCartData={shoppingCartData} />,
      onBack: null,
      onNext: goNextStep,
      title: 'Resumen de operación',
      loading: false,
    },
    {
      Component: (
        <OfferSelectCessionType
          isExternalCession={isExternalCession}
          setIsExternalCession={setIsExternalCession}
        />
      ),
      onBack: goBackStep,
      onNext: goNextStep,
      title: '¿Cómo quieres ceder tu(s) factura(s)?',
      loading: false,
    },
    {
      Component: (
        <OfferSelectBankAndCertificatePassword
          isExternal={isExternalCession}
          bankId={bankId}
          setBankId={setBankId}
          certificatePassword={certificatePassword}
          setCertificatePassword={setCertificatePassword}
          error={error}
        />
      ),
      onBack: goBackStep,
      onNext: () => {
        createOperation();
        goNextStep();
      },
      title: null,
      loading,
      nextButtonText: '¡Operar con Fingo!',
    },
    {
      Component: (<OkOperation />),
      onBack: null,
      onNext: closeShoppingCart,
    },
  ];
  return { offerShoppingSteps, currentStep, goNextStep, goBackStep };
};

export default useDefaultCartSteps;
