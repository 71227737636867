import { useEffect, useState } from 'react';
import { setLocalStorageVariable } from '@fingo/lib/apollo/helpers/local-storage';
import { logIn } from '@fingo/lib/apollo/reactive-variables/localUpdates';

const useWidgetAuth = () => {
  const [isLoading, setIsLoading] = useState(true);
  const handleMessage = ({ data }) => {
    const { value, type } = data;
    switch (type) {
      case 'TOKEN':
        logIn(value);
        break;
      case 'REQUESTING_PLATFORM_ID':
        setLocalStorageVariable('requestingPlatformIdVar', value);
        break;
      default:
    }
    setIsLoading(false);
  };
  useEffect(() => {
    window.addEventListener('message', handleMessage);
    return () => {
      window.removeEventListener('message', handleMessage);
    };
  }, []);
  return isLoading;
};

export default useWidgetAuth;
