import merge from 'lodash/merge';

const getCustomBreakpoints = (customConfig) => ({
  values: {
    desktop: customConfig.desktop,
  },
});

export const defaultBreakpoints = {
  values: {
    xs: 0,
    s: 375,
    sm: 600,
    m: 750,
    md: 900,
    lg: 1200,
    xl: 1536,
  },
};

export const getBreakpoints = (customConfig) => merge(
  {},
  defaultBreakpoints,
  getCustomBreakpoints(customConfig),
);
